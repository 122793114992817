.header {
  .header-box {
    width: 100%;
    height: 193px;
    background-color: $primary-color;
  }
  .header-inner-box {
    padding-top: 37px;
  }
  h1 {
    font-family: "Nunito";
    font-size: 20px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0.5px;
    text-align: center;
    color: $text-white-color;
  }

  @media (max-width: 576px) {
    .header-box {
      height: 145px;
    }
    .header-inner-box {
      padding-top: 17px;
    }
    h1 {
      font-size: 16px;
    }
  }
}
