//fonts
@font-face {
  font-family: "Nunito";
  src: url("../fonts/nunito/Nunito-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

//colors
$primary-color: #469ee7;
$button-background-color: #f99348;
$button-background-hover-color: #f99450;
$text-white-color: #ffffff;

//body
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

//anchor-tag
a {
  text-decoration: none;
}

// buttons
.btn {
  border-radius: 37.5px !important;
  font-size: 20px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
  font-weight: 500;
  font-family: "inter";

  line-height: 55px;
  height: 46px;
  @media (max-width: 576px) {
    height: 33px;
  }
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.btn-primary {
    background-color: $button-background-color;
    border: 1px solid $button-background-color;

    &:hover {
      background-color: $button-background-hover-color;
    }
  }
}

//ordered-list
ul {
  li {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0.5px;
    color: $primary-color;
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  li::marker {
    color: $primary-color;
  }
}
