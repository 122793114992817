.home-page {
  .hero-banner {
    background-image: url("../images/homepage-hero-banner.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 1254px;
    position: relative;
  }
  .hero-banner-sm {
    background-image: url("../images/homepage-hero-banner-sm.png");
    background-size: cover;
    height: 563px;
    margin-top: 145px;
    position: relative;
  }
  .homepage-vector {
    position: absolute;
    left: 0;
    margin-top: 850px;
  }
  .homepage-vector-sm {
    position: absolute;
    left: 0;
    margin-top: 460px;
  }
  .homepage-overlay-top,
  .homepage-overlay-top-sm {
    position: relative;
    z-index: 1;
  }
  .homepage-overlay-top-inner {
    padding-top: 410px;
    padding-right: 0px;
  }
  .homepage-overlay-top-inner-sm {
    padding-right: 45px;
    padding-top: 70px;
    padding-left: 45px;
  }
  .homepage-mockup-top-sm {
    width: 155px;
  }
  .homepage-mockup-top {
    width: 240px;
  }
  h1 {
    font-family: "Inter";
    font-size: 40px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0.5px;
    color: $primary-color;
    text-shadow: 0px 2px 2px #00000040;
  }
  h2 {
    font-family: "Inter";
    font-size: 40px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0.5px;
    color: $primary-color;
  }
  p {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0.5px;
    color: $primary-color;
  }
  .container-after-overlay {
    padding-top: 147px;
  }
  .middle-button {
    padding-top: 61px;
    padding-bottom: 198px;
    .btn-middle {
      width: 268px;
    }
  }
  .middle-button-section {
    background-color: $primary-color;
    height: auto;
    padding-top: 147px;
    padding-bottom: 147px;
  }
  .rounded-circle {
    width: 150px;
    height: 150px;
  }
  .border {
    border: 10px solid $text-white-color !important;
    box-shadow: 0px 4px 12px 0px #00000040;
  }
  .border:hover {
    border: 7px solid $text-white-color !important;
  }
  h3 {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 400;
    line-height: 120px;
    letter-spacing: 0.5px;
    color: $text-white-color;
    text-shadow: 0px 4px 12px #00000040;
  }
  .middle-text-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .bottom-mobile-mockup-section {
    width: 100%;
    height: 776px;
    background-color: $primary-color;
    position: relative;
  }
  .bottom-mobile-mockup-section-sm {
    width: 100%;
    height: 1921px;
    background-color: $primary-color;
    position: relative;
  }
  .homepage-vector-bottom {
    position: absolute;
    bottom: -1px;
  }
  h4 {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 700;
    line-height: 120px;
    letter-spacing: 0.5px;
    color: $primary-color;
  }
  .bottom-mockup-1,
  .bottom-mockup-2,
  .bottom-mockup-3 {
    box-shadow: 0px 4px 44px 0px #00000040;
    border-radius: 47px;
    width: 242px;
  }
  .bottom-overlay {
    position: relative;
    z-index: 3;
    padding-top: 125px;
  }
  .gratitudes-game {
    padding-right: 25px;
  }
  .daily-encouragement {
    padding-left: 10px;
  }
  h5 {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0.5px;
    text-align: left;
    color: $primary-color;
  }
  .bottom-button {
    .btn-bottom {
      width: 268px;
    }
  }
  .mockup-top {
    padding: 22px;
  }
  .homepage-mockup-top {
    box-shadow: 0px 4px 22px 0px #00000054;
    border-radius: 36px;
  }

  @media (max-width: 350px) {
    .homepage-mockup-top-sm {
      width: 133px !important;
    }
    .homepage-overlay-top-inner-sm {
      padding-right: 10px !important;
      padding-top: 110px !important;
      padding-left: 10px !important;
    }
    .container-after-overlay {
      padding-top: 224px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .homepage-mockup-top-sm {
      border-radius: 21px;
    }
  }

  @media (max-width: 375px) {
    .homepage-overlay-top-inner-sm {
      padding-right: 45px;
      padding-top: 130px;
      padding-left: 45px;
    }
    .container-after-overlay {
      padding-top: 224px;
      padding-left: 25px;
      padding-right: 25px;
    }
    .homepage-mockup-top-sm {
      width: 129px;
    }
    .homepage-mockup-top-sm {
      border-radius: 20px;
    }
  }
  @media (max-width: 991px) {
    .hero-banner {
      height: 870px !important;
    }
    .homepage-vector {
      margin-top: 560px !important;
    }
    .homepage-mockup-top {
      width: 160px !important;
      border-radius: 27px !important;
    }
    .homepage-overlay-top-inner {
      padding-top: 275px !important;
    }
    .bottom-overlay {
      padding-top: 245px !important;
    }
    h4 {
      line-height: 35px;
      padding-top: 27px;
      font-size: 18px;
    }
    h3 {
      font-size: 25px;
    }
    // .bottom-mockup-1,
    // .bottom-mockup-2,
    // .bottom-mockup-3 {
    //   width: 600px;
    // }
  }
  @media (max-width: 1024px) {
    .hero-banner {
      height: 1015px;
    }
    .homepage-vector {
      margin-top: 675px;
    }
    .homepage-mockup-top {
      width: 185px;
      border-radius: 32px;
    }
    .homepage-overlay-top-inner {
      padding-top: 337px;
      padding-right: 75px;
    }
    .bottom-overlay {
      padding-top: 180px;
    }
    .bottom-mockup-1,
    .bottom-mockup-2,
    .bottom-mockup-3 {
      width: 237px;
    }
  }
  @media (min-width: 1450px) {
    .hero-banner {
      background-size: cover;
      background-position-y: -256px;
      height: 1977px;
    }
    .homepage-vector {
      margin-top: 1260px;
    }
    .homepage-mockup-top {
      width: 360px;
      border-radius: 56px;
    }
    .homepage-overlay-top-inner {
      padding-top: 735px;
      padding-right: 0px;
      margin-right: 375px;
    }
    .container-after-overlay {
      padding-top: 210px;
    }
    .container {
      width: calc(100vw - 457px) !important;
      max-width: none !important;
    }
    .bottom-overlay {
      padding-top: 277px;
    }
    .bottom-mobile-mockup-section {
      height: 1075px;
    }
    .bottom-mockup-1,
    .bottom-mockup-2,
    .bottom-mockup-3 {
      width: 295px;
    }
    h4 {
      font-size: 28px;
    }
    .gratitudes-game {
      padding-right: 15px;
    }
    .daily-encouragement {
      padding-left: 0px;
    }
  }
  @media (max-width: 576px) {
    h1 {
      font-size: 20px;
      line-height: 28px;
      text-shadow: none;
    }
    h2 {
      font-size: 20px;
      line-height: 28px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
    }
    .container-after-overlay {
      padding-top: 260px;
      padding-left: 45px;
      padding-right: 45px;
    }
    .middle-button {
      .btn-middle {
        width: 100%;
      }
      padding-top: 50px;
      padding-bottom: 100px;
    }
    h3 {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      width: 244px;
    }
    .middle-button-section {
      padding-top: 68px;
      padding-bottom: 68px;
    }
    .button-section-mobile-seperator {
      padding-top: 100px;
    }
    .middle-text-section {
      padding-top: 75px;
      padding-bottom: 75px;
    }
    h4 {
      color: $text-white-color;
      font-size: 20px;
    }
    .bottom-overlay-sm {
      padding-top: 45px;
      position: relative;
      z-index: 3;
    }
    .text-blue {
      color: $primary-color;
    }
    h5 {
      font-size: 20px;
      line-height: 28px;
    }
    .bottom-button {
      .btn-bottom {
        width: 100%;
      }
    }
    .container-bottom {
      padding-left: 45px;
      padding-right: 45px;
    }
    .homepage-mockup-top-sm {
      box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.3294117647);
      border-radius: 25px;
    }
  }

  @media (min-width: 1400px) {
    .container {
      max-width: 1120px;
    }
  }
}
