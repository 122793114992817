.footer {
  .footer-container {
    background-color: $primary-color;
    width: 100%;
    height: auto;
    padding-top: 73px;
    padding-bottom: 144px;
  }
  .footer-title {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 0.5px;
    text-align: left;
    color: $text-white-color;
  }
  .footer-icon {
    padding-left: 40px;
  }
  .footer-element {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: $text-white-color !important;
  }
  .email-icon {
    height: 50px;
    width: 57px;
  }

  @media (max-width: 576px) {
    .footer-container {
      padding-top: 46px;
      padding-bottom: 104px;
    }
    .footer-title {
      font-size: 20px;
      font-weight: 700;
    }
    .footer-element {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
    .instagram-icon {
      width: 115px;
      height: 45px;
    }
    .footer-icon {
      padding-left: 0px;
    }
    .email-icon {
      height: 49px;
      width: 52px;
    }
  }
}

@media (max-width: 576px) {
  .home-page {
    .footer {
      .container {
        padding-left: 45px;
      }
    }
  }
}
